<template>
    <div class="mod-config">
        <el-container>
            <el-aside width="240px">
                <span>组织树:</span>
                <el-tree
                    ref="orgTree"
                    :props="props"
                    :expand-on-click-node="false"
                    node-key="id"
                    :load="loadOrg"
                    @node-click="orgClick"
                    :highlight-current="true"
                    lazy>
                </el-tree>
            </el-aside>
            <el-main>
                <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
                    <el-form-item label="所属组织:">
                        <el-input v-model="dataForm.orgName" placeholder="请选择组织" readOnly clearable
                                  @clear="clearOrg"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="dataForm.key" placeholder="请输入名称或编码" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="getDataList()">查询</el-button>
                        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
                        <el-button type="primary" @click="addOrUpdateHandle(1)">修改</el-button>
                        <el-button type="primary" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">
                            删除
                        </el-button>
                    </el-form-item>
                </el-form>
                <el-table
                    :data="dataList"
                    border
                    v-loading="dataListLoading"
                    @selection-change="selectionChangeHandle"
                    style="width: 100%;">
                    <el-table-column
                        type="selection"
                        header-align="center"
                        align="center"
                        width="50">
                    </el-table-column>
                    <el-table-column
                        prop="orgName"
                        header-align="center"
                        align="center"
                        label="所属部门">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        header-align="center"
                        align="center"
                        label="名称">
                    </el-table-column>
                    <el-table-column
                        prop="code"
                        header-align="center"
                        align="center"
                        label="编码">
                    </el-table-column>
                    <el-table-column
                        prop="description"
                        header-align="center"
                        align="center"
                        label="描述信息">
                    </el-table-column>
                    <el-table-column
                        prop="timeBegin"
                        header-align="center"
                        align="center"
                        label="生效时间">
                    </el-table-column>
                    <el-table-column
                        prop="timeEnd"
                        header-align="center"
                        align="center"
                        label="失效时间">
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        header-align="center"
                        align="center"
                        label="是否启用">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.status==1" type="success">
                                正常
                            </el-tag>
                            <el-tag v-else type="danger">
                                禁用
                            </el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    background
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="totalPage"
                    layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </el-main>
        </el-container>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>

<script>
import AddOrUpdate from './orgPosition-add-or-update';

export default {
    data() {
        return {
            props: {
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },
            dataForm: {
                key: '',
                unitId: '',
                orgName: ''
            },
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false
        };
    },
    components: {
        AddOrUpdate
    },
    activated() {
        this.getDataList();
    },
    methods: {
        clearOrg() {
            this.dataForm.unitId = '';
        },
        loadOrg(node, resolve) {
            this.$client.getOrgUnitListByParent({parent: node.data ? node.data.id : '-1'})
                .then((data) => {
                    const list = [];
                    for (const item of data.list) {
                        item.isLeaf = item.isLeaf === 1 ? true : false;
                        list.push(item);
                    }
                    resolve(list);
                });
        },
        orgClick(data) {
            this.dataForm.unitId = data.id;
            this.dataForm.orgName = data.name;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            this.$client.getPositionListByOrgId({
                current: this.pageIndex,
                size: this.pageSize,
                key: this.dataForm.key,
                unitId: this.dataForm.unitId

            }).then((data) => {
                if (data && data.code === 0) {
                    this.dataList = data.data.records;
                    this.totalPage = data.data.total;
                } else {
                    this.dataList = [];
                    this.totalPage = 0;
                }
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 新增 / 修改
        addOrUpdateHandle(status) {
            let row = [];
            if (status) {
                //修改
                if (!this.preValidate(1)) {
                    return;
                }
                row = this.dataListSelections[0];
            }
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(row, this.dataForm);
            });
        },
        // 删除
        deleteHandle() {
            if (!this.preValidate()) {
                return;
            }
            const ids = this.dataListSelections.map(item => {
                return item.id;
            });
            this.$confirm(`确定删除选中数据？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delOrgPosition(ids).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList();
                            }
                        });
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        preValidate(isSingle) {
            if (this.dataListSelections == null || this.dataListSelections.length < 1) {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            //是否只能选一条数据的校验
            if (isSingle) {
                if (this.dataListSelections.length > 1) {
                    this.$message({
                        type: 'warning',
                        message: '只允许对一条进行操作'
                    });
                    return false;
                }
            }
            return true;
        },
    }
};
</script>
