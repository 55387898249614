<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-container>
      <el-aside width="240px">
        <span class="item-label">组织树:</span>
        <el-tree
          ref="orgTree2"
          :props="props"
          :expand-on-click-node="false"
          node-key="id"
          :load="loadOrg"
          @node-click="orgClick"
          :highlight-current="true"
          lazy>
        </el-tree>
      </el-aside>
      <el-main>


        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
                 label-width="120px">
          <el-form-item label="所属组织" prop="orgName">
            <el-input v-model="dataForm.unitId" placeholder="请选择左则组织树" style="display: none;"></el-input>
            <el-input v-model="dataForm.orgName" placeholder="请选择左则组织树" readOnly></el-input>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item label="编码" prop="code">
            <el-input v-model="dataForm.code" placeholder="编码"></el-input>
          </el-form-item>
          <el-form-item label="描述信息" prop="description">
            <el-input v-model="dataForm.description" placeholder="描述信息"></el-input>
          </el-form-item>
          <el-form-item label="生效时间" prop="timeBegin">
            <el-date-picker
              v-model="pickerTime"
              type="datetimerange"
              @change="pickerTimeChange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="DateTimePickerOption"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否启用" prop="status">
            <el-switch v-model="dataForm.status"></el-switch>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs';
export default {
    data() {
        return {
            DateTimePickerOption: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            props: {
                label: 'name',
                children: 'children',
                isLeaf: 'isLeaf'
            },
            pickerTime: [],
            visible: false,
            dataForm: {
                id: 0,
                orgName: '',
                name: '',
                code: '',
                description: '',
                unitId: '',
                timeBegin: '',
                timeEnd: '',
                status: true,
            },
            dataRule: {
                name: [
                    {required: true, message: '名称不能为空', trigger: 'blur'}
                ],
                code: [
                    {required: true, message: '编码不能为空', trigger: 'blur'}
                ],
                orgName: [
                    {required: true, message: '所属组织不能为空', trigger: 'blur'}
                ],
                timeBegin: [
                    {required: true, message: '生效时间不能为空', trigger: 'blur'}
                ],
                timeEnd: [
                    {required: true, message: '失效时间不能为空', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '是否启用不能为空', trigger: 'blur'}
                ],
            }
        };
    },
    methods: {
        pickerTimeChange(val) {
            this.dataForm.timeBegin = val[0];
            this.dataForm.timeEnd = val[1];
        },
        orgClick(data) {
            this.dataForm.orgName = data.name;
            this.dataForm.unitId = data.id;
        },
        init(_row, obj) {
            const row = Object.assign({}, _row);
            this.dataForm.id = row ? row.id : 0;
            this.visible = true;
            this.dataForm.timeBegin = dayjs().format('YYYY-MM-DD HH:mm:ss');
            this.dataForm.timeEnd = dayjs('2099-12-30').format('YYYY-MM-DD HH:mm:ss');
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                if (this.dataForm.id) {
                    this.dataForm = row;

                    this.dataForm.status = row.status === 1 ? true : false;
                } else {
                    this.dataForm.unitId = obj.unitId;
                    this.dataForm.orgName = obj.orgName;
                }
                this.pickerTime = [this.dataForm.timeBegin, this.dataForm.timeEnd];
            });
        },
        loadOrg(node, resolve) {
            this.$client.getOrgUnitListByParent({parent: node.data ? node.data.id : -1})
                .then((data) => {
                    const list = [];
                    for (const item of data.list) {
                        item.isLeaf = item.isLeaf === 1 ? true : false;
                        list.push(item);
                    }
                    resolve(list);
                });
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const submit = this.dataForm.id ? this.$client.updateOrgPosition : this.$client.saveOrgPosition;
                    submit({
                        id: this.dataForm.id || undefined,
                        name: this.dataForm.name,
                        code: this.dataForm.code,
                        description: this.dataForm.description,
                        unitId: this.dataForm.unitId,
                        timeBegin: this.dataForm.timeBegin,
                        timeEnd: this.dataForm.timeEnd,
                        status: this.dataForm.status ? 1 : 0,
                    }).then(() => {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        });
                        this.visible = false;
                        this.$emit('refreshDataList');
                    });
                }
            });
        }
    }
};
</script>
<style>
  .item-label{
    margin-bottom: 10px;
  }
  .item-label:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
</style>
